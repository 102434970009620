
import { Component, Mixins } from 'vue-property-decorator';
import { VForm } from '@/config/types';
import { covidForm } from '@/config/forms/covid';
import { UiMixin } from '@/mixins/ui-mixin';
import { auth, sleep, ST_core } from '@/config/lib';
import moment from 'moment';

@Component({})
export default class SchedulesUser extends Mixins<UiMixin>(UiMixin) {
  stActivities = 'ST-Activities';
  errorLocation = false;
  isValidForm = false;
  isValidCheckOut = false;
  covidFormDialog = false;
  activitiesDialog = false;
  checkIn = false;
  checkInData: any = {};
  userId = null;
  projects = [];
  activities: any = {};
  form: any = {
    projectId: null,
  };
  covidForm = {};
  covidFormFields = covidForm;
  get refForm() {
    return this.$refs.form as VForm;
  }

  async goToRegisterCheckIn() {
    if (this.$refs.form) {
      await this.refForm.validate();
    }
    if (!this.isValidForm) return;
    if (!this.checkIn) {
      this.covidFormDialog = true;
    }
  }

  async registerCheckIn() {
    const isValidFormCovid = await (this.$refs.formCovid as VForm).validate();
    if (!isValidFormCovid) return;
    this.setLoader(true);
    try {
      const reqCovid: any = await this.$http.post('/covid', {
        answers: this.covidForm,
      });
      this.form.checkIn = new Date();
      this.form.date = moment(this.form.checkIn).format('YYYY-MM-DD');
      this.form.covidForm = reqCovid.data._id;
      this.form.userId = auth.get().user._id;
      this.form.localizationIn = await this.getPosition();
      await this.$http.post('/schedules', {
        ...this.form,
      });
      await this.getCheckIn();
      this.covidFormDialog = false;
    } catch (e) {
      this.alert('Error registrando la hora', 'error');
    }
    this.setLoader(false);
  }

  async registerCheckOut() {
    this.isValidCheckOut = await (this.$refs.activitiesForm as VForm).validate();
    if(!this.isValidCheckOut) return;
    this.setLoader(true);
    try {
      await this.getPosition();
      await this.$http.patch(`/activities/${this.activities._id}`,{
        activities: this.activities.activities,
        userId: auth.get().user._id,
      });
      await this.$http.patch(`/schedules/${this.checkInData._id}`, {
        checkOut: new Date(),
        localizationOut:  await this.getPosition(),
        userId: auth.get().user._id,
      });
      this.activitiesDialog = false;
      await this.getCheckIn();
    } catch (e) {
      this.alert('Error guardando el registro de salida');
    }
    this.setLoader(false);
  }

  async goToRegisterCheckOut() {
    this.setLoader(true);
    try {
      const reqActivities: any = await this.$http.get('/activities', {
        params: {
          schedule: this.checkInData._id,
        },
      });
      if (!reqActivities.data.docs.length) {
        this.alert('Usted no ha añadido actividades hoy', 'error');
      } else {
        this.activitiesDialog = true;
        this.activities = reqActivities.data.docs[0];
        ST_core.delete(this.stActivities);
      }
    } catch (e) {
      this.alert('Error buscando registro', 'error');
    }
    this.setLoader(false);
  }

  async getCheckIn() {
    try {
      const req: any = await this.$http.get('/schedules', {
        params: {
          userId: this.userId,
          rows: 1,
          page: 1,
          date: moment(new Date()).format('YYYY-MM-DD'),
        }
      });
      if (req.data.docs.length) {
        this.checkIn = !req.data.docs[0].checkOut;
        this.checkInData = req.data.docs[0];
        this.form.projectId = this.checkInData.projectId;
      }
    } catch (e) {
      this.alert('Error buscando registro', 'error');
    }
  }

  async getPosition() {
    let pos: any;
    try {
      pos = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });
      return {
        long: pos.coords.longitude,
        lat: pos.coords.latitude,
      };
    } catch (e) {
      this.errorLocation = true;
    }
  }

  async getProjects() {
    const req: any = await this.$http.get('/projects/all');
    this.projects = req.data;
  }

  async mounted() {
    this.covidFormDialog = true;
    this.userId = auth.get().user._id;
    this.setLoader(true);
    await this.getProjects();
    this.covidFormDialog = false;
    try {
      await this.getPosition();
      await this.getCheckIn();
    } catch (e) {
      this.errorLocation = true;
    }
    this.setLoader(false);
  }
}
